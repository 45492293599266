var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pt-3 pb-3 mb-3"},[_c('v-row',[_c('v-card-title',[_c('h2',{staticClass:"text-xl"},[_vm._v("Suivi des Tendances")]),_c('dag-info',{attrs:{"dags":['gtrends_realtime_dag']}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"ml-6 mr-6",attrs:{"append-icon":"mdi-magnify","label":"Recherche (par Tendance, Composition)","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"ml-6 mr-6",attrs:{"items":_vm.trendItems,"headers":_vm.trendHeaders,"options":_vm.options,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"server-items-length":_vm.options.total,"page":_vm.options.page,"pageCount":_vm.numberOfPages},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.trendHeaders),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip))])])]}}}),{key:"item.related_posts",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openPopup(item.related_posts.data)}}},[_vm._v(" "+_vm._s(item.related_posts.total)+" posts found in db ")])]}},{key:"item.trend_url",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"cursor-pointer",attrs:{"small":""},on:{"click":function($event){return _vm.openUrl(item.trend_url)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiOpenInNew)+" ")])]}},{key:"item.ended",fn:function(ref){
var item = ref.item;
return [(item.ended != 'None')?_c('span',{staticClass:"text-gray-600"},[_vm._v(_vm._s(item.ended))]):_c('span',{staticClass:"text-green-600"},[_vm._v("Active")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.categoryDict[item.category]))])]}}],null,true)})],1),_c('v-row')],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Related Posts")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.relatedPosts,"headers":_vm.postHeaders},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.title))])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }