<template>
  <div>
    <v-card class="pt-3 pb-3 mb-3">
      <v-row class="ml-3 mr-3 pt-3">
        <v-col> <date-filter store="gtrends"></date-filter></v-col>
        <v-col>
          <v-text-field
            v-model="minSearchVolume"
            label="Niveau de volume de recherche minimum"
            type="number"
            outlined
          ></v-text-field
        ></v-col>
        <v-col>
          <v-select
            v-model="isActive"
            :items="isActiveList"
            label="Statut"
            outlined
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="ml-3 mr-3">
        <v-col>
          <v-select
            v-model="category"
            :items="categoryList"
            label="Catégorie"
            outlined
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col>
          <apply-filters :disabled="isLoading" outlined color="secondary" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
  },
  data() {
    return {
      isActiveList: [
        { id: "all", name: "Toutes" },
        { id: "only_active", name: "Actives" },
        { id: "only_inactive", name: "Terminées" },
      ],
      categoryList: [],
    };
  },

  setup() {
    const { isLoading, axiosGet } = useAxios();

    return {
      isLoading,
      axiosGet,
    };
  },
  async created() {
    this.categoryList = await this.get_categories();
  },
  methods: {
    async get_categories() {
      const response = await this.axiosGet("/gtrends/categories");
      const originalList = response.data;
      const newList = originalList.map((item) => ({
        id: item.gtrends_cat_id,
        name: item.rmr_cat_name,
      }));
      return newList;
    },
    validateIsActive(isActive) {
      const allowedValues = this.isActiveList.map((entry) => entry.id);

      return allowedValues.includes(isActive) ? isActive : "all";
    },
    validateCategory(category) {
      const allowedValues = this.categoryList.map((entry) => entry.id);

      return allowedValues.includes(category) ? category : 0;
    },
  },

  computed: {
    minSearchVolume: {
      get() {
        const storedValue = this.$store.getters["gtrends/getMinSearchVolume"];

        return storedValue;
      },
      set(e) {
        this.$store.dispatch("gtrends/updateMinSearchVolume", e);
      },
    },
    isActive: {
      get() {
        const storedValue = this.$store.getters["gtrends/getisActive"];
        const value = this.validateIsActive(storedValue);
        if (value !== storedValue) {
          this.$store.dispatch("gtrends/updateisActive", value);
        }

        return value;
      },
      set(e) {
        this.$store.dispatch(
          "gtrends/updateisActive",
          this.validateIsActive(e)
        );
      },
    },
    category: {
      get() {
        const storedValue = this.$store.getters["gtrends/getCategory"];
        const value = this.validateCategory(storedValue);
        if (value !== storedValue) {
          this.$store.dispatch("gtrends/updateCategory", value);
        }
        return value;
      },
      set(e) {
        this.$store.dispatch("gtrends/updateCategory", e);
      },
    },
  },
};
</script>

<style scoped>
/* Ajoutez des styles spécifiques à ce composant si nécessaire */
</style>
