<template>
  <div>
    <v-card class="pt-3 pb-3 mb-3">
      <v-row>
        <v-card-title>
          <h2 class="text-xl">Suivi des Tendances</h2>
          <dag-info :dags="['gtrends_realtime_dag']" />
        </v-card-title>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            class="ml-6 mr-6"
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par Tendance, Composition)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          class="ml-6 mr-6"
          :items="trendItems"
          :headers="trendHeaders"
          :options.sync="options"
          :sort-by="options.sortBy"
          :sort-desc="options.sortDesc"
          :server-items-length="options.total"
          :page="options.page"
          :pageCount="numberOfPages"
        >
          <template
            v-for="header in trendHeaders"
            v-slot:[`header.${header.value}`]="{ header }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.related_posts="{ item }">
            <a href="#" @click.prevent="openPopup(item.related_posts.data)">
              {{ item.related_posts.total }} posts found in db
            </a>
          </template>
          <template v-slot:item.trend_url="{ item }">
            <v-icon
              small
              @click="openUrl(item.trend_url)"
              class="cursor-pointer"
            >
              {{ icons.mdiOpenInNew }}
            </v-icon>
          </template>
          <template v-slot:item.ended="{ item }">
            <span v-if="item.ended != 'None'" class="text-gray-600">{{
              item.ended
            }}</span>
            <span v-else class="text-green-600">Active</span>
          </template>
          <template v-slot:item.category="{ item }">
            <span>{{ categoryDict[item.category] }}</span>
          </template>
        </v-data-table>
      </v-row>
      <v-row>
        <!-- Fenetre warning pour trends sans related post -->
      </v-row>
    </v-card>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Related Posts</span>
        </v-card-title>
        <v-card-text>
          <v-data-table :items="relatedPosts" :headers="postHeaders">
            <template v-slot:item.title="{ item }">
              <a :href="item.url" target="_blank">{{ item.title }}</a>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.text-green-600 {
  color: #16a34a;
}
.text-gray-600 {
  color: #4b5563;
}
</style>

<script>
import DagInfo from "@/components/common/menus/DagInfo";
import useAxios from "@/hooks/useAxios";
import { mdiOpenInNew } from "@mdi/js";
import debounce from "lodash/debounce";

export default {
  name: "trendsTable",
  components: {
    DagInfo,
  },

  data() {
    return {
      dialog: false,
      relatedPosts: [],
      updated_at: "Nan",
      icons: {
        mdiOpenInNew,
      },
      trendItems: [],
      trendHeaders: [
        {
          text: "Tendance",
          value: "trend",
          sortable: true,
          tooltip: "Tendance sur google",
        },
        {
          text: "Catégorie",
          value: "category",
          sortable: true,
          tooltip: "Category from google trends",
        },
        {
          text: "Départ",
          value: "started",
          sortable: true,
          tooltip: "Heure du début de la tendance",
        },
        {
          text: "Fin",
          value: "ended",
          sortable: true,
          tooltip: "Heure de fin de la tendance",
        },
        {
          text: "scraping update",
          value: "data_updated",
          sortable: true,
          tooltip: "Heure du dernier crawler",
        },
        {
          text: "Composition",
          value: "trend_composition",
          sortable: false,
          tooltip: "Composition de la tendance",
        },
        {
          text: "SV",
          value: "search_volume",
          sortable: true,
          tooltip: "Search Volume",
        },
        {
          text: "Articles correspondants",
          value: "related_posts",
          sortable: true,
          tooltip: "Articles en liens dans la base de données",
        },
        {
          text: "Exploration",
          value: "trend_url",
          sortable: false,
          tooltip: "Lien vers la tendance sur google",
        },
      ],
      postHeaders: [
        {
          text: "Title",
          value: "title",
          sortable: false,
        },
        {
          text: "Date",
          value: "created",
          sortable: false,
        },
        {
          text: "Site",
          value: "site",
          sortable: false,
        },
        {
          text: "lifetime_sessions",
          value: "lifetime_sessions",
          sortable: false,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 10,
        sortBy: [],
        sortDesc: [],
      },
      numberOfPages: 0,
      search: "",
      search_column: "",
      categoryDict: {},
    };
  },

  setup() {
    const { axiosGet, axiosPost, isLoading } = useAxios();

    return {
      axiosGet,
      isLoading,
      axiosPost,
    };
  },

  methods: {
    debouncedUpdateData: debounce(async function () {
      await this.getData();
    }, 500),
    async openPopup(relatedPosts) {
      this.relatedPosts = relatedPosts;
      this.dialog = true;
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    async getData() {
      const params = {
        last_trends: 100,
        page: this.options.page,
        sv_min: this.minSearchVolume,
        category: this.category,
        is_active: this.isActive,
        min_date_start: this.dateRange[0],
        max_date_start: this.dateRange[1],
        limit: this.options.itemsPerPage,
        sort_by: this.options.sortBy.join(","),
        sort_desc: this.options.sortDesc.join(""),
        search: this.search,
        // search_column: this.search_column,
      };
      const response = await this.axiosGet("/gtrends/get_trends", params);
      // console.log("get_trends", response);
      this.options.total = response.data.total;
      this.trendItems = response.data.data;
      this.numberOfPages = Math.ceil(
        response.data.total / this.options.itemsPerPage
      );
      this.$store.dispatch("common/updateApplyFilters", false);
    },
    async getCategories() {
      const response = await this.axiosGet("/gtrends/categories");
      const originalList = response.data;
      this.categoryDict = originalList.reduce((acc, item) => {
        acc[item.gtrends_cat_id] = item.rmr_cat_name;
        return acc;
      }, {});
    },
  },
  async created() {
    await this.getCategories();
    // await this.getData();
  },
  computed: {
    filtersApplied() {
      return this.$store.getters["common/getApplyFilters"];
    },
    dateRange() {
      return this.$store.getters["gtrends/getDates"];
    },
    minSearchVolume() {
      return this.$store.getters["gtrends/getMinSearchVolume"];
    },
    isActive() {
      return this.$store.getters["gtrends/getisActive"];
    },
    category() {
      console.log(this.$store.getters["gtrends/getCategory"]);
      return this.$store.getters["gtrends/getCategory"];
    },
  },
  watch: {
    filtersApplied() {
      this.debouncedUpdateData();
    },
    options() {
      this.debouncedUpdateData();
    },
    search() {
      this.debouncedUpdateData();
    },
    // search_column() {
    //   this.getData();
    // },
  },
};
</script>
